import React from "react";
import Link from "next/link";
import { useSession } from "next-auth/client";

import { H6 } from "@andertons/design-system/components/Text.jsx";
import { PrimaryButton } from "@andertons/design-system/components/Button.jsx";
import Card from "@andertons/design-system/components/Card.jsx";
import Container from "@andertons/design-system/components/Container.jsx";

import { CardList, CardItem } from "../components/pages/index/CardList";
import Img from "../components/Img";

Index.propTypes = {};

export default function Index() {
  const [{ user = {} }] = useSession();
  return (
    <Container>
      <CardList>
        <CardItem>
          <Card
            Image={(props) => (
              <Img
                {...props}
                src={require("../components/images/create-video.jpg")}
                webP={require("../components/images/create-video.jpg?webp")}
              />
            )}
          >
            <Link href="/video/new">
              <a>
                <PrimaryButton
                  as="span"
                  classNames="w-full mb-2"
                  data-cy="new-video"
                >
                  <H6>Create Video</H6>
                </PrimaryButton>
              </a>
            </Link>
          </Card>
        </CardItem>

        <CardItem key="my-videos">
          <Card
            Image={(props) => {
              return (
                <Img
                  {...props}
                  src={require("../components/images/my-videos.jpg")}
                  webP={require("../components/images/my-videos.jpg?webp")}
                />
              );
            }}
          >
            <Link
              href={{ pathname: "/videos", query: { authorId: user.email } }}
            >
              <a>
                <PrimaryButton
                  as="span"
                  classNames="w-full mb-2"
                  data-cy="my-videos"
                >
                  <H6>My Videos</H6>
                </PrimaryButton>
              </a>
            </Link>
          </Card>
        </CardItem>

        <CardItem key="all-videos">
          <Card
            Image={(props) => {
              return (
                <Img
                  {...props}
                  src={require("../components/images/all-videos.jpg")}
                  webP={require("../components/images/all-videos.jpg?webp")}
                />
              );
            }}
          >
            <Link href="/videos">
              <a>
                <PrimaryButton
                  as="span"
                  classNames="w-full mb-2"
                  data-cy="all-videos"
                >
                  <H6>See All</H6>
                </PrimaryButton>
              </a>
            </Link>
          </Card>
        </CardItem>

        <CardItem key="reports">
          <Card
            Image={(props) => {
              return (
                <Img
                  {...props}
                  src={require("../components/images/reports.jpg")}
                  webP={require("../components/images/reports.jpg?webp")}
                />
              );
            }}
          >
            <PrimaryButton classNames="w-full mb-2">
              <H6>Reports</H6>
            </PrimaryButton>
          </Card>
        </CardItem>
      </CardList>
    </Container>
  );
}
