import React from "react";
import propTypes from "prop-types";
import { motion } from "framer-motion";
import { cardList, cardItem } from "./CardList.module.css";

const transition = {
  staggerChildren: 0.1,
};

const cardListVariants = {
  hidden: {
    transition,
  },
  show: {
    transition,
  },
};

CardList.propTypes = {
  children: propTypes.arrayOf(propTypes.element),
};
export function CardList(props) {
  const { children, ...rest } = props;
  return (
    <motion.ul
      {...rest}
      className={cardList}
      initial="hidden"
      exit="hidden"
      animate="show"
      variants={cardListVariants}
    >
      {children}
    </motion.ul>
  );
}

const cardTransition = { duration: 0.1 };

const cardItemVariants = {
  hidden: { opacity: 0, y: 100, transition: cardTransition },
  show: { opacity: 1, y: 0, transition: cardTransition },
};

CardItem.propTypes = {
  children: propTypes.element,
};
export function CardItem(props) {
  const { children } = props;
  return (
    <motion.li {...props} className={cardItem} variants={cardItemVariants}>
      {children}
    </motion.li>
  );
}
