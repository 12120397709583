import React from "react";
import propTypes from "prop-types";

Img.propTypes = {
  src: propTypes.string.isRequired,
  webP: propTypes.string,
};

export default function Img(props) {
  const { src, webP, ...rest } = props;
  return (
    <picture {...rest}>
      {webP && <source srcSet={webP} type="image/webp" />}
      <source srcSet={src} type="image/jpeg" />
      <img src={src} />
    </picture>
  );
}
